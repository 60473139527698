'use client';

import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { isDev } from '@wello-client/common/utils';

import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { SUB_DOMAIN, useDeviceInfoStore } from '@/stores/DeviceStore';

const HomeLayout = ({ children }: PropsWithChildren) => {
  const router = useCustomRouter();
  const isKbSubDomain = useDeviceInfoStore(
    (state) => state.subDomain === SUB_DOMAIN.KB_PAY,
  );

  useEffect(() => {
    if (!isKbSubDomain && !isDev) {
      router.replace(ROUTES.HOME.pathname);
    }
  }, [isKbSubDomain, router]);

  return <>{children}</>;
};

export default HomeLayout;
